<template>
  <div id="content" data-elbglobals="page_type:opc">
    <AlertBanner v-if="!productIsVisible" :text="translate('alert_message_preview_mode')"/>
    <TheOpcTemplate id="page-template"/>
  </div>
</template>

<script>
import pageMixin from '@/components/basic/mixins/pageMixin'
import TheOpcTemplate from '@/components/opc/templates/TheOpcTemplate'
import { mapActions, mapState } from 'pinia'
import { useOpcStore } from '@/stores/opc'

const AlertBanner = () => import('@/components/basic/atoms/AlertBanner.vue')

export default {
  name: 'TheOpcPage',
  components: {
    AlertBanner,
    TheOpcTemplate
  },
  mixins: [pageMixin],
  computed: {
    ...mapState(useOpcStore, ['product', 'updating']),
    productIsVisible () {
      return this.updating || this.product.is_enabled
    },
    metaOnCreate () {
      return false
    },
    title () {
      const manufacturer = this.product.general.manufacturer_name ?? ''
      const productName = this.product.general.name ?? ''

      return `${manufacturer} ${productName}`.trim()
    },
    description () {
      return this.product.general.name
        ? this.product.description.meta_description
        : this.translate('fallback_meta_description')
    },
    meta () {
      return {
        title: this.title,
        description: this.description,
        robots: this.product.general.robots
      }
    },
  },
  methods: {
    ...mapActions(useOpcStore, ['refreshProduct'])
  },
  beforeRouteEnter(_t, _f, next) {
    next((vm) => {
      vm.refreshProduct()
    })
  }
}
</script>
